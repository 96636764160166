<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-12">
            <strong>Branches Management</strong>
            <a-button
              class="btn btn-outline-primary ml-2"
              @click="sendToForm('Create')"
              >Tambah Data</a-button
            >
          <a-select
            show-search
            class="ml-2"
            placeholder="Select Kode BPR"
            v-model="selectedidbpr"
            style="width: 200px;"
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="getData"
          >
            <a-select-option
              v-for="(data, index) in databpr"
              :key="index"
              :value="data.sandibpr"
              >{{ data.sandibpr + ' - ' + data.namabpr }}</a-select-option
            >
          </a-select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
                style="
                  margin-left: -26px;
                  margin-right: -26px;
                  margin-top: -21px;
                "
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 300 }"
          size="small"
          :pagination="{
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span>
                {{ text }}
              </span>
            </template>
          </template>
          <span slot="action" slot-scope="text, record">
            <a-icon
              type="edit"
              class="text-warning"
              @click="sendToForm('Update', record, record.id)"
              />
            <a-divider type="vertical"/>
            <a-icon
              type="delete"
              class="text-danger"
              @click="showDeleteConfirm(record)"
              />
          </span>
          <template slot="appList" slot-scope="text, record">
            <a-tag v-for="(data, index) in record.appLists" :key="index">{{ data.appName }}</a-tag>
          </template>
          <template slot="id_Default_App" slot-scope="text, record">
            <a-tag color="blue">{{ findName(record) }}</a-tag>
          </template>
        </a-table>
      </div>
    </div>
    <formModal ref="formModal" :key="componentKey" :table="table" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import formModal from '../form'

const data = []

export default {
  components: {
    formModal,
  },
  data() {
    return {
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      popinput: {
        password: '',
        re_password: '',
      },
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Sandi Kantor',
          dataIndex: 'sandi_kantor',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.sandi_kantor
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Kode',
          dataIndex: 'kode',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.alamat
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.keterangan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Induk',
          dataIndex: 'induk',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.induk
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      selectedidbpr: '',
      databpr: [],
      table: 'branches',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      data,
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getMaster()
  },
  methods: {
    findName(record) {
      if (record.appLists.length === 0) {
        return 'Tidak ada List app'
      } else {
        var tindex = record.appLists.findIndex(x => x.id === record.id_Default_App)
        // console.log('tindex', tindex)
        return record.appLists[tindex].appName
      }
    },
    hide(index) {
      this.data[index].changePassPop = false
      this.popinput = {
        password: '',
        re_password: '',
      }
    },
    async changePassword(index) {
      if (this.popinput.password === this.popinput.re_password) {
        var fd = {
          id: this.data[index].id,
          password: this.popinput.password,
        }
        var res = await lou.customUrlPut('manage/branches/changepassword', fd)
        if (res) {
          this.data[index].changePassPop = false
          lou.shownotif('Success', res.message, 'success')
          this.popinput = {
            password: '',
            re_password: '',
          }
        }
      } else {
        lou.shownotif('Not Valid!', 'Password tidak sama!')
      }
    },
    sendToForm(action, data = {}) {
      this.$refs.formModal.showModal(action, data, this.selectedidbpr)
    },
    async getMaster() {
      var res = await lou.readMaster('manage/branches/bprlist', false, true)
      if (res) {
        this.databpr = res.data
        if (this.databpr !== null && this.databpr.length !== 0) {
          this.selectedidbpr = this.databpr[0].sandibpr
          this.getData()
        }
      }
    },
    async getData() {
      this.componentKey += 1
      var res = ''
      res = await lou.readMaster('manage/branches?bpr_id=' + this.selectedidbpr, false, true)
      // console.log('res.data', res.data)
      this.data = res.data
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('manage/branches?branch_id=' + deldata.id)
          this.data = response.data
          this.getData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>
